import 'assets/style/global.css';

import Vue from 'vue';
import App from './player.vue';
import eventBus from 'utils/_eventBus';
import store from '@/store';
import '@/assets/icons';
import i18n from '@/locales/i18n';

import {
  Button,
  Select,
  Dialog,
  Input,
  Slider,
  Message,
  Card,
  Popover,
  Avatar,
  Tabs,
  TabPane,
  Option,
  Checkbox,
  InputNumber,
  MessageBox,
  Tooltip,
  Radio,
  Link,
  Collapse,
  CollapseItem,
} from 'element-ui';

/**
 *  重写ElementUI的Message
 */
const showMessage = Symbol('showMessage');
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single);
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single);
  }
  info(options, single = true) {
    this[showMessage]('info', options, single);
  }
  error(options, single = true) {
    this[showMessage]('error', options, single);
  }
  [showMessage](type, options) {
    Message[type](options);
  }
}

Vue.use(Button);
Vue.use(Select);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Slider);
Vue.use(Input);
Vue.use(Card);
Vue.use(Popover);
Vue.use(Avatar);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(InputNumber);
Vue.use(Tooltip);
Vue.use(Radio);
Vue.use(Link);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = new DonMessage();

Vue.prototype.$eventBus = eventBus;

Vue.config.productionTip = false;



import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = "https://live.hhdglm.com/api/" // 默认连接地址 
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //配置请求头


new Vue({
  i18n,
  // router,
  store,
  render: h => h(App),
}).$mount('#app');
