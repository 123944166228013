<template lang="pug">
  div#app(v-if="showqy")
    div(v-if="isPC")
      div#header
        comp-header
      div#content
        div#left
          comp-player(ref="player")
        div#right
          comp-message(ref="message")
    div(v-else)
      div#mbheader
        comp-header
      div#mbcontent
        div#mbleft
          comp-player(ref="player")
        div#mbright
          comp-message(ref="message")
</template>

<script>
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import compHeader from '@/components/comp-header';
import compPlayer from '@/components/comp-player';
import compMessage from '@/components/comp-message';
import {
  SET_SDK_APP_ID,
  SET_USER_SIG,
  SET_PLAYER_DOMAIN,
  SET_ROOM_ID,
  SET_ROOM_NAME,
  UPDATE_USER_INFO,
  SET_ANCHOR_USER_ID,
} from '@/constants/mutation-types';
import {
  // sdkAppId,
  // expireTime,
  // secretKey,
  playerDomain,
  // userInfo,
  // roomInfo,
  // anchorUserInfo,
} from '@/config/basic-info-config';
import { mapState } from 'vuex';
export default {
  name: 'App',
  data() {
    return {
      isPC:true, //
      isMobile:'false', //是否是手机
      showqy:false,// 参数不全就不显示页面 也不会初始化直播间
      liveInfo:{},
      userInfo : {
        userId: '456789', //使用userId生成签名
        // userName: "",
      },
      userSig:'',
    };
  },
  computed: {
    // ...mapState({
    //   // userInfo: 'userInfo',
    //   roomId: 'roomId',
    // }),
  },
  components: {
    compHeader,
    compPlayer,
    compMessage,
  },
  methods: {
    //获取url参数
    getRequest() { 
      var url = location.search; 
      var theRequest = new Object(); 
      if (url.indexOf("?") != -1) { 
          let str = url.substr(1); 
          let strs = str.split("&"); 
          for(let i = 0; i < strs.length; i++) { 
            theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]); 
          } 
      } 
      return theRequest; 
    },

    handlePlayerInfo() {
      // 拉流端用户信息
      const roomId = this.liveInfo.roomNo; //一致
      const sdkAppId = 1400622978;
      const roomName = this.liveInfo.title; //一致
      const anchoruserId = this.liveInfo.roomNo; //一致
      const userSig = this.userSig;

      if(!roomId||!userSig||!sdkAppId||!roomName||!anchoruserId){
        document.getElementsByTagName('body')[0].innerHTML="参数不全"+
        `!roomId:${roomId}!userSig:${userSig}!sdkAppId:${sdkAppId}!roomName:${roomName}!anchoruserId:${anchoruserId}`
        return
      }

      console.log("参数------",roomId,userSig,sdkAppId,roomName,anchoruserId)

      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // alert(flag)
      if(flag){
        this.isPC = false;
        // alert("移动端")
      }
      this.showqy = true; //参数校验通过 显示直播间
      // if (sdkAppId === '' || secretKey === '') {
      //   alert(`${this.$t('basic.Please configure your SDKAPPID')}\r\n\r\nconfig/basic-info-config.js`);
      // }
      // const generator = new LibGenerateTestUserSig(sdkAppId, secretKey, expireTime);
      // const userSig = generator.genTestUserSig(userInfo.userId);

      this.$store.commit(SET_SDK_APP_ID, sdkAppId);
      this.$store.commit(SET_USER_SIG, userSig);
      this.$store.commit(SET_PLAYER_DOMAIN, playerDomain);
      this.$store.commit(SET_ROOM_ID, Number(roomId));
      this.$store.commit(SET_ROOM_NAME, roomName);
      this.$store.commit(SET_ANCHOR_USER_ID, anchoruserId);
      // alert(this.userInfo.userId)
      this.$store.commit(UPDATE_USER_INFO, {
        userId: this.userInfo.userId,
        userName: this.userInfo.userName,
        // userAvatar: userInfo.userAvatar,
      });
    },
    // 退出直播间
    async handleExit() {
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
    },
  },
  async created() {
    // this.handlePlayerInfo();
    if(localStorage.getItem("userId")){
      this.userInfo.userId = localStorage.getItem("userId")
      // this.userInfo.userId = "15879804936"
    }else{
      this.userInfo.userId = new Date().getTime()+"";
      // this.userInfo.userId = "15879804936"
      localStorage.setItem("userId",this.userInfo.userId)
    }
    //通过userId生成签名
    this.$axios.get(`tx/genUserSig?roomNo=${this.userInfo.userId}`)
    .then(res =>{
      console.log(res)
      this.userSig = res.data.data
    })
    var urlPara = this.getRequest();
    console.log(urlPara)
    const roomId = urlPara.roomId; //一致
    console.log(roomId)
    if(roomId){
      localStorage.setItem("roomId",roomId)
      this.$axios.get(`liveInfo/getLiveInfo?id=${roomId}`)
      .then(res =>{
        console.log(res);
        if(res.data.errorCode===1){
           this.liveInfo = res.data.data
            localStorage.setItem("roomNo",res.data.data.roomNo)
           this.handlePlayerInfo();
        }else{
            alert("获取直播信息失败")
        }
      })
    }
    
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
  },
};
</script>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  background-color $backgroundColor
  color $fontColor
  width 100%
  height 100%
  position relative
  #header
    width 100%
    height 52px
    background-color $themeColor
  #mbheader
    width 100%
    height 52px
    background-color $themeColor
  #content
    width 100%
    position absolute
    left 0
    bottom 0
    top 52px
    display: flex
    #left
      height 100%
      flex-grow 1
      background-color $backgroundColor
    #right
      width 30%
      min-width 300px
      max-width 406px
      height 100%
      background-color $IMThemeColor
  #mbcontent
    width 100%
    position absolute
    left 0
    bottom 0
    top 52px
    display: block
    #mbleft
      display: block
      height 50%
      background-color $backgroundColor
    #mbright
      width 100%
      min-width 300px
      // max-width 406px
      height 80%
      background-color $IMThemeColor
</style>
