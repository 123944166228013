<template >
  <!-- 显示招聘信息 -->
  <div class="video-record">
    <div style="height:1000px">
      <!-- {{copmanyList}} -->
      <div>
        <!-- <p  style="font-weight:700;margin:3px;text-decoration: underline;text-align:left;padding-left:5px">{{company.companyName}} </p> -->
        <!-- {{dateTimeFormate(new Date(company.createTime))}} -->
      </div>
      <el-collapse  accordion >
          <el-collapse-item   :name="index"  v-for="(company,index) in copmanyList" :key="company.id">
            <template slot="title" >
              {{company.companyName}}
            </template>
            <div v-html="company.companyProfile">

            </div>
          </el-collapse-item>
          
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'video-record',
  data() {
    return {
      activeNames: ['1'],
      copmanyList:[
        
      ],
      Interval:'',
    };
  },
  mounted(){
    this.getCompany();
    this.Interval = setInterval(()=>{
      this.getCompany();
    },2000)
  },
  beforeDestroy(){
    clearInterval(this.Interval)
  },
  methods:{
    getCompany(){
       this.$axios.get(`liveCompany/getCompany?id=${localStorage.getItem("roomId")}&createTime=desc&rows=1000`)
      .then(res =>{
          console.log(res)
          if(res.data.errorCode===1){
              this.copmanyList = res.data.data
          }else{
              // alert(res.data.message);
          }
          this.loadingtable = false;
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
  .video-record
    width 100%
    height 100%
    color $grayFontColor
    padding-top 10px
    padding-bottom:  30px
    font-size 16px
    padding-left 20px
    padding-right 20px
    overflow:auto
  /deep/ .el-collapse-item__header
      background-color #383f54
      border-bottom none
      color #fff
  /deep/ .el-collapse
      border none 
</style>
<style  scoped>
   
</style>
<i18n>
{
	"en": {
		"Coming soon": "Coming soon"
	},
	"zh": {
		"Coming soon": "敬请期待"
	}
}
</i18n>
